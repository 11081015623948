import { createRoot } from 'react-dom/client'
import { Layout } from './app/layout'
import React from 'react'
import { ThemeProvider } from '@mui/material'
import { theme } from './app/Theme'

const container = document.getElementById('app')! // add ! for typescript
const root = createRoot(container) // createRoot(container!) if you use TypeScript

root.render(
    <ThemeProvider theme={theme}>
        <Layout />
    </ThemeProvider>
)
