import * as React from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

const steps = [
    {
        label: (
            <>
                <strong>Insert a USB stick</strong>
            </>
        ),
        description: (
            <>
                Plug a USB stick into your computer.
                <em>Please DO NOT USE a USB-C stick.</em>
            </>
        )
    },
    {
        label: (
            <>
                <strong> Connect to the printer's WIFI</strong>
            </>
        ),
        description: (
            <>
                Enter your WIFI's SSID in the SSID field.
                <br />
                Enter your WIFI's password in the password field.
                <br />
                <br />
                <em>The SSID & Password of your WIFI can be found on the back of your router. Please ask your administrator for more info.</em>
            </>
        )
    },
    {
        label: (
            <>
                <strong>Download the printer's configuration file</strong>
            </>
        ),
        description: (
            <>
                Click the
                <strong> DOWNLOAD CONFIGURATION FILE </strong>
                button and the file shall automatically be downloaded.
            </>
        )
    },
    {
        label: (
            <>
                <strong>Transfer the file to the USB stick</strong>
            </>
        ),
        description: (
            <>
                Locate the downloaded file on your computer and place the file in the USB stick's root directory (the first location you see when you access the
                USB stick).
                <br />
                <br />
                The Feetneeds printer will not read it if you put it into any sub-folder.
            </>
        )
    },
    {
        label: (
            <>
                <strong>Connect the USB stick to the printer</strong>
            </>
        ),
        description: <>Safely unplug your USB stick and plug it into the USB slot of the Feetneeds printer.</>
    },
    {
        label: (
            <>
                <strong>Wait for the printer's WIFI to appear</strong>
            </>
        ),
        description: (
            <>
                The printer will automatically look for the configuration on the USB stick and use it as the new credentials.
                <br /> You should now be connected.
            </>
        )
    },

    {
        label: (
            <>
                <strong>Extra Info</strong>
            </>
        ),
        description: (
            <>
                If the Feetneeds printer does not read your USB stick, please make sure it's formatted to FAT32 format.
                <br />
                <br />
                The Feetneeds printer will also write information such as IP address and report logs to the USB stick which can be opened from a computer for
                troubleshooting.
            </>
        )
    }
]

export default function TextMobileStepper() {
    const theme = useTheme()
    const [activeStep, setActiveStep] = React.useState(0)
    const maxSteps = steps.length

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    return (
        <Box
            color={'#2D3C49'}
            boxShadow={'90px 18px 200px -70px #60F2FF, -90px -18px 200px -70px #FF9448'}
            bgcolor={'aliceblue'}
            sx={{ maxWidth: 400, flexGrow: 1 }}>
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default'
                }}>
                <Typography color={'#2D3C49'}>{steps[activeStep].label}</Typography>
            </Paper>
            <Box
                justifyContent={'center'}
                flexDirection={'column'}
                display={'flex'}
                bgcolor={'aliceblue'}
                sx={{
                    height: 200,

                    maxWidth: 400,
                    width: '100%',
                    p: 2
                }}>
                {steps[activeStep].description}
            </Box>

            <MobileStepper
                sx={{ color: 'darkcyan' }}
                variant="progress"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button sx={{ color: 'orange' }} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        Next
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button sx={{ color: 'orange' }} onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Back
                    </Button>
                }
                LinearProgressProps={{
                    color: 'inherit',
                    sx: {
                        color: '#60F2FF'
                    }
                }}
            />
        </Box>
    )
}
