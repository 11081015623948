import { createTheme, ThemeProvider } from '@mui/material/styles'
export const theme = createTheme({
    typography: {
        fontFamily: '"Epilogue", Arial, sans-serif'
    },

    components: {
        MuiInput: {
            styleOverrides: {
                root: {
                    // #2FF3ED // light blue green
                    // #60F2FF // light blue
                    // #FF9448 orange
                    // #2D3C49 dark grey

                    '&:before': {
                        borderBottom: '2px solid #60F2FF'
                    },
                    '&:after': {
                        borderBottom: '2px solid orange'
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottom: '2px solid orange'
                    },
                    // input to orange
                    '& input': {
                        color: 'teal'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#2D3C49',
                    '&.Mui-focused': {
                        color: '#FF9448'
                    }
                }
            }
        }
    }
})
