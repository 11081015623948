import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import undraw_broadcast from '../images/undraw_broadcast2.png'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload'
import TextMobileStepper from './Stepts'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'

export const Home = () => {
    const [open, setOpen] = React.useState(false)
    const [showBox, setShowBox] = useState(false)
    // const phone = useMediaQuery('(max-width:600px)')
    // user credentials
    const [ssid, setSsid] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false)
    //track if the user entered both credentials and activate the disabled submit button
    const isDisabled = ssid === '' || password === '' || isSubmitted
    //change the submit button name to submitted when submitted
    const submitName = isSubmitted ? 'Downloaded' : 'Download configuration file'

    //handle the user input
    const handleSsidChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSsid(e.target.value)
    }

    // handle the user input
    const handlePwChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    // submit the user credentials
    const handleSubmit = () => {
        setIsSubmitted(true)
        // console.log('ssid:', ssid) // disabled for now
        // console.log('pw:', password)
        generateJson()
    }

    // generate text file with valid json encapsulating the ssid and pw
    const generateJson = () => {
        const data = {
            SSID: ssid,
            password: password
        }
        const json = JSON.stringify(data)
        console.log(json, 'json')
        const blob = new Blob([json], { type: 'application/json' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'credentials.json'
        a.click()
        URL.revokeObjectURL(url)

        // reset the form

        setSsid('')
        setPassword('')
        setIsSubmitted(false)
        //reactivate the button
    }

    return (
        <>
            <Stack
                color={'#2D3C49'}
                width={'100vw'}
                sx={{
                    backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800])
                }}>
                <Container
                    sx={{
                        backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]),

                        width: '100vw',
                        height: '90vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <Stack alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'}>
                        <Stack>
                            <Card sx={{ width: 400, maxWidth: 600, boxShadow: '90px 180px 200px -70px #FF9448, -90px -180px 200px -70px #60F2FF' }}>
                                {showBox ? (
                                    <Box bgcolor={'aliceblue'} height={300}>
                                        <TextMobileStepper />

                                        <CardMedia sx={{ height: 300, backgroundColor: '#FF9448', opacity: '10%' }} image={undraw_broadcast} title="image" />
                                    </Box>
                                ) : (
                                    <CardMedia sx={{ height: 300, backgroundColor: '#FF9448' }} image={undraw_broadcast} title="image" />
                                )}
                                <CardContent>
                                    <Typography color={'#2D3C49'} gutterBottom variant="h4" component="div">
                                        Connection Backup
                                    </Typography>
                                    <Stack>
                                        <Button
                                            onClick={() => {
                                                if (showBox) {
                                                    setOpen(false)
                                                } else {
                                                    setOpen(true)
                                                }
                                                setShowBox(!showBox)
                                            }}
                                            startIcon={showBox ? <ExpandLessRoundedIcon /> : ''}
                                            variant="contained"
                                            disableElevation
                                            sx={{
                                                bgcolor: '#60F2FF',
                                                color: '#3B4955',
                                                fontWeight: 'bold',

                                                '&:hover': {
                                                    bgcolor: '#FF9448',
                                                    color: 'white'
                                                }
                                            }}
                                            size="small">
                                            {showBox ? 'Hide instructions' : 'Instructions'}
                                        </Button>
                                        {/*  the website communicates to the user that the credentials are safe not stored */}
                                        <Typography gutterBottom variant="overline" fontWeight={600} color="#FF9448">
                                            We do not store your credentials
                                        </Typography>
                                    </Stack>

                                    {/* user can enter WIFI's ssid and pw */}
                                    <Stack>
                                        <TextField
                                            // error
                                            id="standard-error-helper-text"
                                            label="SSID"
                                            placeholder="SSID:"
                                            helperText="Please enter the WIFI's SSID here."
                                            variant="standard"
                                            onChange={handleSsidChange}
                                            value={ssid}
                                        />
                                        <TextField
                                            id="standard-error-helper-text"
                                            label="Password"
                                            placeholder="Password:"
                                            helperText="Please enter the WIFI's Password here."
                                            variant="standard"
                                            onChange={handlePwChange}
                                            value={password}
                                        />
                                    </Stack>
                                </CardContent>
                                <CardActions>
                                    {/* Button becomes available only when the user entered credentials */}
                                    <Tooltip title="Download credentials config file">
                                        <Button
                                            endIcon={<SimCardDownloadIcon />}
                                            // size="small"
                                            disabled={isDisabled}
                                            sx={{
                                                fontWeight: 'bold',
                                                borderColor: '#60F2FF',
                                                color: '#FF9448',
                                                '&:hover': {
                                                    bgcolor: 'unset',
                                                    color: '#FF9448',
                                                    borderColor: 'unset'
                                                }
                                            }}
                                            variant="outlined"
                                            onClick={handleSubmit}>
                                            {submitName}
                                        </Button>
                                    </Tooltip>
                                </CardActions>
                            </Card>
                        </Stack>
                    </Stack>
                </Container>
            </Stack>
        </>
    )
}
