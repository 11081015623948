import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import { Stack } from '@mui/material'

function Copyright() {
    return (
        <Typography textAlign={'center'} variant="body2" color="text.secondary">
            {'Copyright © '}
            <Link color="inherit" href="https://plasmics.com/">
                Plasmics GmbH
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

export default function StickyFooter() {
    return (
        <Box color={'#2D3C49'}>
            <CssBaseline />

            <Box
                marginTop={{ sm: 'auto', md: 10, lg: 'auto' }}
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800])
                }}>
                <Container
                    sx={{ justifyContent: 'center', alignItems: 'center', justifyItems: 'center', display: 'flex', flexDirection: 'column' }}
                    maxWidth="sm">
                    <Typography fontWeight={600} textAlign={'center'} variant="caption">
                        We don't retain your WiFi credentials on any database or server. Any data you input and download remains stored exclusively on your own
                        computer.
                    </Typography>
                    <Copyright />
                </Container>
            </Box>
        </Box>
    )
}
