import * as React from 'react'
import { Home } from './Home'
import StickyFooter from './StickyFooter'
import { CssBaseline } from '@mui/material'
export const Layout = () => {
    return (
        <React.StrictMode>
            <CssBaseline />
            <Home />
            <StickyFooter />
        </React.StrictMode>
    )
}
